import React from "react";
import YouTube from "../../components/mdxComponents/YouTube";
import Helmet from "react-helmet";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import { currentYear } from "../../components/Helpers";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import H from "../../components/Headline";
import { useAlternateLangs } from "../../components/Hreflangs";

const breadCrumbLevels = {
  Hjem: "/",
  "Analytics Consulting": "/no/analyse-rådgivning",
  "Tag Management": "/no/tagg-administrasjon"
};

// hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/tag-management"
);


const FAQs = `
  {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [
        {
          "@type": "Question",
          "name": "Hva er et tag management system?",
          "acceptedAnswer": {
          "@type": "Answer",
          "text": "Et tag management system er et JavaScript-bibliotek for å implementere og konfigurere analyse- og markedsføringstagger. HTML-tagger, JavaScript-snippets eller sporingspiksler legger til funksjoner på nettstedet eller appen din og kan installeres med bare noen få klikk. Etter at en tagg er publisert til containeren, vil koden bli generert og lagt til nettsidens side (DOM). Ingen ny kildekode distribueres til serveren."
          }
        },
        {
        "@type": "Question",
        "name": "Hvilket problem løser et TMS?",
        "acceptedAnswer": {
        "@type": "Answer",
        "text": "Over tid har flere sosiale medieplattformer kommet opp med sine egne tagger (Linkedin, Snapchat, Twitter, Pinterest, Instagram, osv.) og nettsider har blitt oppblåst med deres snippets. Som et resultat har selskaper hatt en økning i webutviklingskostnader. Tagg administratorer løser disse problemene fordi implementeringer kan skaleres og dermed spare tid, noe som betyr at selskaper kan spare penger."
        }
      }
    ]
  }
  `;

const TagManagement = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title={`Tag Management forklart (med ${currentYear} Sammenligning)`}
        description="Lær hva tag management systemer gjør, deres fordeler, og hvorfor de ble så populære (inkludert en sammenligning av de nåværende TMS-leverandørene)."
        lang="no"
        canonical="/no/tagg-administrasjon"
        image="tag-management-hero"
        alternateLangs={alternateLangs}
      />
      <MainContent article>
        <ImgScreenshot
          src="tag-management/tag-management-hero.png"
          alt="logoer av TMS-plattformleverandører ved siden av hverandre på mørk bakgrunn"
        />
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
        <H as="h1">Tag Management</H>
        <p className="baseline">
          Tag management nyter økende popularitet og en voksende community. De fleste moderne nettsteder kan ikke lenger klare seg uten et tag management system, så nå bruker omtrent <a href="https://w3techs.com/technologies/overview/tag_manager" target="_blank">30% av alle nettsteder en tag manager</a>.
        </p>
        <p>Men dette er en ganske ny markedsføringsteknologi trend.</p>
        <p>
          I de siste årene har en ny sektor av tag management løsninger dukket opp. Selv i dag kommer nye løsninger på markedet, på grunn av endrede forskrifter og utviklende markedsføringsteknologi.
        </p>
        <p className="baseline">
          Et godt eksempel på den økende interessen er det verdensomspennende søket etter "Google Tag Manager" (GTM) - det mest populære tag management systemet (TMS) med en <a href="https://trends.builtwith.com/analytics/tag-management/traffic/Entire-Internet" target="_blank">markedsandel på 94% bruksfordeling på hele internett</a>:
        </p>
        <ImgScreenshot
          src="tag-management/google-tag-manager-growth-of-popularity-past-10-years.png"
          alt="Graf fra Google Trends som viser økende interesse for Google Tag Manager"
          caption="Økningen i søk etter 'Google Tag Manager' siden 2010 illustrerer den økende interessen for tag management systemer. Ironisk nok ble den generelle termen 'tag management' aldri så populær som TMS-plattformleverandørene."
          className="article-img"
        />
        <p className="baseline">Selv om den ekstreme veksten har pågått en stund nå, er det estimert at bruken av tag manager vil <b>fortsette å vokse</b> med en årlig vekstrate på <a href="https://www.businesswire.com/news/home/20191209005363/en/Global-Tag-Management-System-Market-Analysis-Report" target="_blank">5.7% frem til 2024.</a></p>
        <H as="h2">Hva er et tag management system?</H>
        <p>
          Et tag management system er et JavaScript-bibliotek for å implementere og konfigurere analyse- og markedsføringstagger.</p>
        <p>
          HTML-tagger, JavaScript-snippets eller sporingspiksler legger til funksjoner på nettstedet eller appen din og kan installeres med bare noen få klikk.
        </p>
        <H as="h3">Eksempler på tagger</H>
        <ul>
          <li>Google Analytics-sporing</li>
          <li>Video-sporing</li>
          <li>Retargeting-piksler (Facebook, Google Ads, Linkedin etc.)</li>
          <li>Påmeldingsskjemaer</li>
        </ul>
        <p>
          Implementering av tagger kan gjøres gjennom et grafisk brukergrensesnitt (GUI) for enkel installasjon.
        </p>
        <p>Etter at en tag er publisert til containeren, vil koden bli generert og lagt til nettsidens side (DOM). Ingen ny kildekode distribueres til serveren.</p>
        <p>Det mest populære TMS er <Link to="/no/hva-er-google-tag-manager">Google Tag Manager</Link>. Det kommer med en rekke tag-typer og maler tilgjengelig for tredjepartsleverandører, slik at du kan implementere tagger med bare noen få klikk.</p>
        <p>Implementeringen er basert på 3 komponenter:</p>
        <ul>
          <li><strong>Tagger</strong> (kode som skal legges til på siden) </li>
          <li><strong>Triggere</strong> (regler med betingelser som sier når taggen skal utløses) </li>
          <li><strong>Variabler</strong> (dynamiske data som skal brukes i tagger eller triggere)</li>
        </ul>

        <ImgScreenshot
          src="generic/google-tag-manager-overview-tab-en.png"
          alt="Google Tag Manager oversikt-fane"
          className="article-img"
          caption="Oversikt over Google Tag Manager med panelet til venstre som viser faner for konfigurasjon av tagger, triggere og variabler."
        />
        <p>Konfigurasjonen utføres med enkle skjemaer og inndatafelt i stedet for å måtte kode det. </p>
        <p>Dette reduserer feil og gjør implementeringen av nettstedstagger <b>tilgjengelig for digitale markedsførere uten programmeringsbakgrunn.</b></p>

        <ImgScreenshot
          src="event-tracking/google-tag-manager-event-trigger-configuration.png"
          alt="Trigger-konfigurasjon i GTM"
          className="article-img"
          caption="Trigger-konfigurasjon i GTM. Den definerer når en tag skal kjøres. Denne kjører når et hvilket som helst HTML-element som samsvarer med CSS-velgeren blir klikket."
        />



        <H as="h2">Hvilket problem løser et TMS?</H>
        <p>
          Med web 2.0 og forbedrende markedsføringsteknologi har nye muligheter for digitale markedsførere dukket opp. De har gjort det mulig å analysere brukeratferd for segmentering og bruke dataene til reklamekampanjer.
        </p>
        <p>For å kjøre slike målrettede kampanjer måtte flere <b>tagger eller piksler</b> først legges til på nettstedet. Etterpå kan sporingspikslen kategorisere besøkende og deres atferd for å remarketere til dem på Facebook eller Google Ads.</p>
        <p>Over tid har flere sosiale medieplattformer kommet med sine egne tagger (Linkedin, Snapchat, Twitter, Pinterest, Instagram, osv.) og nettsteder har blitt oppblåst med deres snippets.</p>
        <p>Det ble ikke uvanlig å se 20 til 40 individuelle tagger i <code>{`<head>`}</code> seksjonen på en side. </p>
        <p>Grunnlaget for dette taggesystemet er et godt konfigurert <Link to="/no/hendelsessporing">event tracking-oppsett</Link>. Alle relevante knapper må være utstyrt med JavaScript-funksjoner. Når et element klikkes, vil JavaScript varsle den relevante markedsføringstaggen.</p>
        <p>Taggene krevde stort sett de samme brukermetrikkene, men hvis du ønsket å legge til en annen dimensjon eller metrikk, ble det raskt en tungvint manuell oppgave. Så administrasjonen av disse taggene alene ble et enda større problem.</p>
        <p>Utviklere måtte bruke mange timer på å lime inn JavaScript-kode i HTML-elementer. Å vite nøyaktig hvordan koden skulle skrives eller hvilke variabler som kunne sendes med ble en ny organisatorisk oppgave.</p>
        <p>Selskaper som ønsket å kjøre kampanjer ofte måtte sette opp event tracking på hver nye landingsside for å holde kampanjetracking i gang. Å holde hovedtagger, parametere og variabler vedlikeholdt og i tråd med oppdateringer ble en ekstra oppgave.</p>
        <p>Enhver eksperimentell idé om å spore en ny interaksjon ble en innsats som var vanskelig å rettferdiggjøre. Kodeendringer ble for arbeidskrevende.</p>
        <p>Kort sagt, endringer og eksperimentering var vanskelig og ufleksibelt. Men konverteringssporing måtte opprettholdes for å måle kampanjens ytelse. Hvis ikke det ble målt riktig, ville det ellers være vanskelig å forbedre.</p>
        <p>Som et resultat økte selskapenes webutviklingskostnader. Problemet kunne bare løses med egenutviklede biblioteker, noe som dessverre førte til at utviklerteam løste problemet i siloer og gjenoppfant hjulet.</p>
        <p>Markedet var modent for innovasjon.</p>

        <p>Tagg administratorer løser disse problemene fordi implementeringer kan skaleres og dermed <b>spare tid</b>, noe som betyr at selskaper kan <b>spare penger</b>.</p>
        <p>Ironisk nok implementeres et tag management system også gjennom en <Link to="/no/wiki-analytics/container-merke">container tag</Link> på alle sider. Men alle senere tagger kan legges til via TMS selv.</p>
        <p>Tag manager-koden må bare legges til og distribueres én gang i begynnelsen, og alle påfølgende tagger implementeres gjennom det grafiske brukergrensesnittet til tag manager-verktøyet.</p>

        <ImgScreenshot
          src="tag-management/matomo-trigger-types.png"
          alt="Liste over tilgjengelige trigger-typer i Matomo Tag Manager-verktøyet"
          className="article-img"
          caption="Typer av triggere i Matomo. Triggere definerer når en bestemt kode skal kjøres, og det finnes en lang liste å velge fra - ingen kodeferdigheter kreves."
        />

        <p>Arbeidsmengden er redusert og prosedyren <b>sterkt forenklet</b>.</p>
        <p>Gjennom den forenklede implementeringen kunne <Link to="/no/google-tag-manager-konsulent">tag management-konsulenter</Link> deretter konfigurere datainnsamlingen av sporingskoder. Denne nye rollen i organisasjonen fokuserer på JavaScript-programmering utelukkende for webanalyse og digitale markedsføringstjenester.</p>
        <p>Hver regel og hvert datapunkt kunne nå brukes på tvers av flere tagger og til og med på tvers av flere domener.</p>
        <p>En tag management plattform har mange fordeler. Så mange faktisk, at du sjelden hører noen klager.</p>

        <H as="h2">Fordeler</H>
        <p>
          En av de store fordelene med tag management systemer er at en teknisk markedsfører kan implementere markedsføringstagger uten å være avhengig av en utvikler.
        </p>

        <p>Det grafiske brukergrensesnittet gjør det enkelt for en digital markedsfører å utføre grunnleggende implementeringer som ellers ville vært for vanskelig i råkode.</p>

        <p>Som et <b>eksempel</b>, her er listen over markedsføringsverktøy og analysetagger du kan installere med <b>Matomo Tag Manager</b>:</p>
        <ImgScreenshot
          src="tag-management/matomo-tag-templates.png"
          alt="Liste over tag-typer i Matomo"
          className="article-img"
          caption="Liste over tag-typer fra Matomo - Tagger kommer med en forhåndsdefinert mal, slik at bare skjemafelt må fylles ut for implementering."
        />
        <p>Nedenfor ser du en liste over GTM sine tilgjengelige <b>tag-alternativer</b> (<a href="https://support.google.com/tagmanager/answer/6106924?hl=en" target="_blank" noopener>komplett liste over støttede tagger</a>). Sammenlignet har GTM flest tag-maler tilgjengelig samt <a href="https://tagmanager.google.com/gallery/#/?page=1" target="_blank" noopener>tilpassede maler</a>.</p>
        <ImgScreenshot
          src="tag-management/gtm-tag-types.png"
          alt="Liste over tagger fra GTM"
          className="article-img"
          caption="Liste over tagger fra GTM - Jeg måtte forkorte tag-listen av plasshensyn. Det er enda flere tag-maler hvis du ser under tilpassede maler."
        />

        <p className="baseline">Siden distribusjon av tagger skjer på sekunder og et markedsføringsteam ikke lenger må vente på kodeimplementering på serveren, sparer bruk av et TMS <b>tid, kostnader og reduserer overhead prosjektledelse</b>.<br />Dette bekreftes i en rapport fra 2015 av Econsultancy, hvor <a href="https://tealium.com/blog/tag-management/6-compelling-statistics-tag-management/" target="_blank">73% av markedsførere sa at bruk av et TMS bidrar til å redusere kostnader</a>.</p>
        <p>Med denne fleksibiliteten er smidige arbeidsflyter mulig som tillater gradvis kampanjeoptimaliseringer, tester og eksperimenter.</p>
        <p>Digitale markedsføringskampanjer kan kjøres i flere varianter mens de retter seg mot mer spesifikke grupper, noe som øker konverteringer og gir et godt grunnlag for å lære hvordan grupper reagerer på tilpassede meldinger.</p>
        <p>Selskaper som allerede har tilgjengelige datasiloer, som databaser, CRM-er og API-er kan bruke et <Link to="/no/data-lag">data layer</Link> for å gjøre dataene tilgjengelige for kampanjetracking-regler.</p>
        <p>En annen undervurdert fordel er de tilgjengelige innstillingene for arbeidsområder, miljøer og brukerrettigheter som utgjør en administrasjonsløsning for å gi tilgang til byråer eller eksterne konsulenter. Tilgang kan gis på sekunder for å la noen jobbe med en individuell tag eller hele taggingplanen.</p>

        <p>Oppsummering, <b>fordelene med tag management</b> er som følger:</p>
        <ul>
          <li>Kostnadsbesparelser på grunn av lavere implementeringskostnader</li>
          <li>Raske implementeringer</li>
          <li>Økt fleksibilitet da endringer og rettelser kan implementeres på sekunder</li>
          <li>Robust og stabil integrasjon av eksterne datakilder</li>
          <li>Mulighet til å tildele forskjellige brukerrettigheter til eksterne partnere enkelt</li>
        </ul>
        <H as="h3">Ulemper</H>
        <p>Det er en fundamentalt begrunnet bekymring for at <b>lastetiden til et nettsted blir langsommere</b> ved bruk av et TMS.</p>
        <p>Dette er imidlertid kun til en svært begrenset grad, på grunn av asynkron lasting av tags.</p>
        <p className="baseline">Nettsteder med en godt konfigurert TMS laster vanligvis <b>raskere</b> enn hvis tags ble implementert direkte i koden. En rapport fra Econsultancy fra 2015 sier at <a href="https://tealium.com/blog/tag-management/6-compelling-statistics-tag-management/" target="_blank">64% av TMS-brukere ser raskere ytelse på nettstedet</a>.</p>
        <p>Fordi optimalisering av lastetid ofte blir brutalt neglisjert, og på grunn av den minimale tapte hastigheten forårsaket av en TMS, finnes det vanligvis langt mer effektive metoder for å forbedre lastetiden enn å velge bort tag management-verktøy helt.</p>

        <p>Og nå, hvem tror du hadde nok interesse i å lette digital annonsering og konverteringssporing til å utvikle en løsning gratis? Det må være et selskap som tjener på at andre driver digital annonsering 😉.</p>

        <H as="h2">Plattform sammenligning</H>

        <p>Her er en sammenligning av de viktigste plattformene på tag management system-markedet:</p>


        <table>
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>Pris</th>
              <th>Data<br />eierskap</th>
              <th>Tilgjengelighet<br />av eksperter</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><b>GTM (gratis)</b></td>
              <td>gratis</td>
              <td>nei</td>
              <td>høy</td>
            </tr>
            <tr>
              <td><b>GTM 360</b></td>
              <td>høy</td>
              <td>ja</td>
              <td>høy</td>
            </tr>
            <tr>
              <td><b>Adobe Launch</b></td>
              <td>høy</td>
              <td>ja</td>
              <td>medium</td>
            </tr>
            <tr>
              <td><b>Tealium iQ</b></td>
              <td>medium</td>
              <td>ja</td>
              <td>lav</td>
            </tr>
            <tr>
              <td><b>Matomo</b></td>
              <td>lav</td>
              <td>ja</td>
              <td>lav</td>

            </tr>
            <tr>
              <td><b>Piwik</b></td>
              <td>medium</td>
              <td>ja</td>
              <td>lav</td>


            </tr>
          </tbody>
        </table>
        <p>For bedrifter faller valget for det meste på premium plattformer, som <b>Google Tag Manager 360, Adobe Launch</b> (etterfølgeren til Adobe DTM) eller <b>Tealium iQ</b>, fordi de oppfyller bedriftenes personvernkrav for kundedata.</p>
        <p>
          Prisen på en premium TMS fra Google 360, Adobe eller Tealium kan ikke defineres nøyaktig, fordi de selges i konfigurerbare bedrifts pakker sammen med andre analyse løsninger.
        </p>

        <p>
          <u>Prisene er derfor kun omtrentlige og bør betraktes som en grov veiledning</u>. Individuelle priser er kjent for å variere.
        </p>

        <p>Hvis du vil se hvordan <b>Tealium Tag Manager</b> ser ut, kan du se på deres demo video:</p>
        <YouTube id="HF1ZiPQYP6M" />
        <br />
        <p>På samme måte er dette en video hvor du kan se <b>Adobe Launch</b> grensesnittet og hvordan en sidelastingsregel er satt opp i det:</p>
        <YouTube id="JR2mRfKU18Y" />
        <br /><br />
        <p>Det mest populære tag management-verktøyet er imidlertid <a href="https://tagmanager.google.com/" target="_blank">gratisversjonen av Google Tag Manager</a>.</p>
        <H as="h2">Vanlige brukstilfeller</H>

        <p>Datainnsamling for webanalyse, digital markedsføring eller datavitenskapelig forskning er de vanligste bruksområdene for tag management systemer.</p>


        <H as="h3">Webanalyse</H>
        <p>Webanalyssystemer som Google Analytics er raske å sette opp og lettere å skalere over flere domener ved bruk av en TMS. Eventuelle påfølgende tillegg eller endringer implementeres raskt og krever færre ressurser, og det er derfor tag management har blitt en standard for digital analyse de siste årene.</p>
        <p><b>Konverteringsrateoptimalisering (CRO)</b> er også i stor grad basert på metrikker fra en analyse tag og er derfor sterkt knyttet.</p>

        <H as="h3">Digital markedsføring</H>
        <p>Annonser på plattformer som Facebook, Twitter, Instagram og Google Ads er populære kanaler for trafikkanskaffelse. Digital annonsering gjennom retargeting med bannere eller Adwords er derfor det mest vanlige bruksområdet.</p>
        <p>Slik markedsføringsteknologi er basert på tags som krever datapunkter fra en eller flere digitale eiendommer for å lage brukersegmenter. Derfor er styring av tags på en kontrollerbar, skalerbar måte en forutsetning.</p>
        <p><b>Affiliate markedsføring</b> er basert på å tilskrive konverteringer til trafikkhenvisninger og er også sterkt avhengig av cookie-logikk implementert gjennom tag managers.</p>

        <H as="h3">Datavitenskap</H>
        <p>Med fremveksten av AI har behovet for data økt siden datamodeller må trenes på store mengder data.</p>
        <p>Eventuelle data som ikke kan hentes fra statiske sider, samles vanligvis inn med en tag management-løsning. Derfra sendes det videre til en datalagring som er tilgjengelig for datamodellering.</p>

        <H as="h2">Tag Management Consulting</H>
        <p>Konsulenttjenester for tag management dukket opp samtidig med veksten i markedet: Ytterligere leverandører dukket opp med nye løsninger og prisnivåer som måtte tas i betraktning.</p>
        <p>Også, <b>bruker databeskyttelseslover i EU</b> (GDPR) endret kravene for konfigurasjoner og ga personvernsfokuserte leverandører et løft fremover.</p>
        <p>Konsulenttjenester for tag management konsentrerer seg om plattformvalg, implementering, validering og vedlikehold av systemet.</p>
        <p>Tag Management konsulenter blir vanligvis leid inn fra byråer som fokuserer på digital annonsering, webanalyse eller de er <Link to="/no/google-tag-manager-konsulent">frilanskonsulenter</Link>.</p>

        <H as="h2">Finnes det tilfeller hvor en TMS ikke er den riktige løsningen?</H>
        <p><b>Et tag management system er riktig valg for de fleste nettsteder og apper</b>. Kun nettsteder som opererer i sektorer med spesifikke krav til databeskyttelse bør nøye vurdere hvordan infrastrukturen er utformet for å overholde databeskyttelseslovgivningen.</p>
        <p>Dette er imidlertid vanligvis ikke et argument mot bruk av en TMS, men snarere et argument for å velge den riktige.</p>
        <p>En annen vurdering kan være ytelsen til nettstedet.</p>
        <p>Hvis det ikke er satt opp riktig, kan en TMS gjøre et nettsted tregere. Nisjer hvor annonsering spiller en viktig rolle kan slite med langsomme lastetider. Å spare noen få hundre millisekunder kan gi verdi, men det er tvilsomt om fordelene ved raskere lastetid rettferdiggjør høyere kostnader for administrering av sporingspiksler.</p>
        <p>Avhengig av kapasiteten til interne ressurser er det mulig å drive et nettsted og digital markedsføring uten en TMS, men dette er vanligvis unntakstilfeller.</p>
        <p>Ethvert nettsted som ikke har et ekstremt antall sider eller nisjekrav for tredjeparts tags, bør kunne kontrollere sine HTML- og JavaScript-tags med en TMS.</p>
        <Helmet>
          <script type="application/ld+json">{FAQs}</script>
        </Helmet>
      </MainContent>
    </React.Fragment>

  </Layout>
);

export default TagManagement;
